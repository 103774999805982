@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Oswald:r%7CNoto+Serif:r,i,b,bi&subset=latin,latin-ext,latin,latin-ext');

body {
  background-color:#212121;
  padding-right: 0 !important;
}

.App-logo {
  height: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}


.App-header {
  background-color: #ffffff;
   min-height: calc(100vh - 12em); 
   display: flex; 
  flex-direction: column;
  align-items: center;
  font-size: calc(20px );
}

.App-link {
  color: #61dafb;
}

.css-k8jybf-MuiButtonBase-root-MuiButton-root:hover {
  opacity:60%
}
.sub-menu-item3:hover{
  background-color:rgba(0,0,0,0.7) !important
}
.burger-nav {
  min-height: auto !important;
}