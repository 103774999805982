@import url('https://fonts.googleapis.com/css?family=Oswald:r%7CNoto+Serif:r,i,b,bi&subset=latin,latin-ext,latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');
/* body */
table.styled-table > tbody > tr >td{
    padding:2px;
    font-family: "Noto Serif", serif;
    font-weight: 400;
}
table.styled-table > thead > tr >th{
    padding:5px;
    font-family: "Oswald",sans-serif;
    font-weight: 600;

}

span {
    padding-top:0px !important;
    padding-bottom:0px !important;
}
#root > div > header.App-header > main > div > div.MuiBox-root.css-yyckeg > div > div > span{
    background-color: black;
}

div.MuiModal-root{
    padding: 4px!important;
} 
h5{
    margin:0px
}
h6{
    margin:0px
}
/* button.MuiButtonBase-root{
    padding: 2px;
} */
p{
    margin-top:2px;
    margin-bottom:8px
}