@import url('https://fonts.googleapis.com/css?family=Oswald:r%7CNoto+Serif:r,i,b,bi&subset=latin,latin-ext,latin,latin-ext');

#simple-tab-0,#simple-tab-1 {
    -webkit-font-smoothing: antialiased;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    -webkit-appearance: none;
    text-decoration: none;
    font-family: "Noto-Serif ";
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 90px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    flex-direction: column;
    color: #030405;
}
css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #1976d2; 
}

#simple-tabpanel-0 > h1,#simple-tabpanel-1 > h1{
    margin-top: 20px;
    font-family: "Oswald",sans-serif;
    font-weight: 400;
    font-size: min(24px,6vw);
    color: #444;
}

#root > div > header.App-header > main > div > div.MuiBox-root.css-1gsv261 > div > div > span {
/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
font-family: "Noto-Serif ";
-webkit-font-smoothing: antialiased;
text-align: center;
font-size: calc(20px );
white-space: nowrap;
position: absolute;
height: 2px;
bottom: 0;
/* transition: all 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
background-color: #030405;
left: 102.125px;
width: 127.016px;}

.reg-button {
    background-color:#333333 !important;
    color: #ffffff !important;
    font-family: "Noto-Serif" !important;
    font-weight:bold !important;}
.forgot-pass{
    text-decoration: underline
}
p {
    font-family: "Noto-Serif" !important;
}