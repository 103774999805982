@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:r%7CNoto+Serif:r,i,b,bi&subset=latin,latin-ext,latin,latin-ext');

div.textsida {
    text-align: left;
    align-items: flex-start;
    /* padding-left: 5em; */
    /* padding-right: 5em;  */
    width:80%;
    max-width: 700px;
}
/* header */
div.textsida > h1{
    font-family: "Oswald, sans-serif";
    font-weight: 600;
    font-size: min(32px,8vw);
    color: #444;
    margin-block-end: 0.1em;
}
div.textsida > h2{
    font-family: "Oswald, sans-serif";
    font-weight: 600;
    font-size: min(24px,6vw);
    color: #444;
    margin-block-end: 0.1em;
}
/* body */
div.textsida > p{
    color:#333;
    font-family: "Noto Serif, serif";
    font-weight: 400;
    font-size:min(20px,4vw);
    margin-block-start: 0.1em;
    margin-block-end: 0.4em;
}
div.textsida > p>a {
    text-decoration: underline!important;
}
div.textsida > li{
    color:#333;
    font-family: "Noto Serif, serif";
    font-weight: 400;
    font-size:min(20px,4vw);
    margin-block-start: 0.1em;
    margin-block-end: 0.4em;
}
figcaption {
    color:#333;
    font-family: "Noto Serif, serif";
    font-weight: 400;
    font-size:min(20px,4vw);
    margin-block-start: 0.1em;
    margin-block-end: 0.4em;
}