.Map {
  /* text-align: center; */
}

.App-link {
  color: #61dafb;
}

.leaflet-container {
  width: 100%;
  height: 83vh;
  /* padding-top: 200px; */
}
/* #panel1a-header > div.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content { */

 .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
  margin: 0px!important;
  height: 20px!important;
}
.MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin:0px!important;
}
.MuiAccordionSummary-content {
  margin: 1px !important;;
}
[class*="Mui-expanded"]{
  margin: 0px!important;
  min-height: 20px!important;
}