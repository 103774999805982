
.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 8px;
    padding: 5px;
  }
  
  .container img {
    width: 100%;
    height: auto;
    display: block;
    transition: all 100ms ease-out;
  }
  
  .container img:hover {
    transform: scale(1.04);
  }
.content {
  grid-area: content;
}
.wrapper {
  display: grid;
  gap: 5px;
  grid-template-areas:
    "content"
    "sidebar"
}
@media (min-width: 500px) {
  .wrapper {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "content sidebar "
  }
}
@media (min-width: 700px) {
  .wrapper {
    grid-template-columns: 3fr 1fr;
    grid-template-areas:
      "content sidebar"
      "content sidebar"
  }
}
